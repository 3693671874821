<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-7 px-0">
              <div class="col-4 pr-0">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    :placeholder="$t('mealsystem.placeholder.search')"
                    v-model="search"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-4">
                <vs-select
                  v-if="groups"
                  v-model="searchGroup"
                  :options="groups"
                  :placeholder="$t('mealsystem.placeholder.search_groups')"
                  label="name"
                  :reduce="(groups) => groups.recipe_group_id"
                />
              </div>

              <b-button
                variant="secondary"
                class="mr-3 ml-3 pr-2"
                @click.prevent="removeFilters"
              >
                <i class="fa fa-times icon-md"></i>
              </b-button>
              <b-button variant="primary" @click.prevent="searchRecipes">
                {{ $t("button.filter") }}
              </b-button>
            </div>
            <div class="card-toolbar">
              <router-link
                  :to="{ name: 'recipes_groups' }"
                  class="btn btn-outline-primary mr-3"
                >
                  {{ $t("mealsystem.recipes.button.groups") }}
              </router-link>
              <b-button
                variant="success"
                v-b-modal.modal-recipe-edit
                @click="setNewRecipe()"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                </span>
                {{ $t("mealsystem.recipes.button.create") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th width="150">
                  {{ $t("mealsystem.recipes.label.recipe_number") }}
                </th>
                <th>{{ $t("mealsystem.recipes.label.recipe_name") }}</th>
                <th>{{ $t("mealsystem.recipes.label.group") }}</th>
                <th>{{ $t("mealsystem.recipes.label.heat_treatment") }}</th>
                <th>{{ $t("mealsystem.recipes.label.prepared_quantity") }}</th>
                <th>{{ $t("mealsystem.recipes.label.meal_count") }}</th>
                <th width="90"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(recipe, index) in recipes.data" :key="index">
                <td>
                  <span>{{ recipe.recipe_number }} </span>
                </td>

                <td>
                  <span class="font-weight-bolder">{{ recipe.name }}</span>
                </td>

                <td>
                  <span v-if="recipe.group != null">{{
                    recipe.group.name
                  }}</span>
                  <span v-else class="text-muted">-</span>
                </td>

                <td>
                  <span>{{ recipe.heat_treatment }}</span>
                </td>
                <td>
                  <span>{{ recipe.prepared_quantity }}</span>
                </td>
                <td>
                  <span>{{ recipe.meal_count }}</span>
                </td>

                <td>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-danger
                      btn-sm
                      btn-icon
                    "
                    v-b-tooltip.left="
                      $t('mealsystem.recipes.title.delete_recipe')
                    "
                    v-b-modal.modal-recipe-delete
                    @click="setRecipe(recipe)"
                    ><i class="ki ki-bold-close icon-sm text-danger"></i
                  ></a>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-primary
                      btn-sm
                      btn-icon
                    "
                    v-b-tooltip.left="
                      $t('mealsystem.recipes.title.edit_recipe')
                    "
                    v-b-modal.modal-recipe-edit
                    @click="getRecipeDetail(recipe)"
                    ><i class="ki ki-gear icon-md"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination :data="recipes.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->

    <b-modal
      id="modal-recipe-delete"
      :okTitle="$t('button.delete')"
      okVariant="danger"
      :title="$t('mealsystem.recipes.title.confirm_delete_recipe_title')"
    >
      <span
        v-html="$t('mealsystem.recipes.title.confirm_delete_recipe_text')"
      ></span>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="danger" @click="deleteRecipe(selectedRecipe)">
          {{ $t("mealsystem.recipes.button.delete_recipe") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-recipe-edit"
      :okTitle="$t('button.save')"
      okVariant="success"
      size="lg"
      :title="$t('mealsystem.recipes.title.edit_recipe')"
    >
      <h3>{{ selectedRecipe.name }}</h3>

      <ValidationObserver ref="form">
        <b-tabs card active-nav-item-class="bg-primary text-white">
          <b-tab :title="$t('mealsystem.recipes.title.tab_main_info')">
            <div class="row">
              <div class="col-6">
                <ValidationProvider
                  name="name"
                  rules="required|min:2"
                  v-slot="{ errors }"
                >
                  <span class="text-muted mb-2 d-block">{{
                    $t("mealsystem.recipes.label.recipe_name")
                  }}</span>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="
                      $t('mealsystem.recipes.placeholder.recipe_name')
                    "
                    v-model="selectedRecipe.name"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  name="recipe_number"
                  rules="required|min:1"
                  v-slot="{ errors }"
                >
                  <span class="text-muted mt-5 mb-2 d-block">{{
                    $t("mealsystem.recipes.label.recipe_number")
                  }}</span>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="
                      $t('mealsystem.recipes.placeholder.recipe_number')
                    "
                    v-model="selectedRecipe.recipe_number"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider name="group" v-slot="{ errors }">
                  <span class="text-muted mt-5 mb-2 d-block">{{
                    $t("mealsystem.recipes.label.group")
                  }}</span>
                  <vs-select
                    v-if="groups"
                    v-model="selectedRecipe.group"
                    :options="groups"
                    :placeholder="$t('mealsystem.recipes.placeholder.group')"
                    label="name"
                    :reduce="(groups) => groups.recipe_group_id"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6">
                <ValidationProvider name="heat_treatment" v-slot="{ errors }">
                  <span class="text-muted mb-2 d-block">{{
                    $t("mealsystem.recipes.label.heat_treatment")
                  }}</span>
                  <vs-select
                    v-model="selectedRecipe.heat_treatment"
                    :options="heat_treatment_types"
                    :placeholder="
                      $t('mealsystem.recipes.placeholder.heat_treatment')
                    "
                    :clearable="false"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  name="prepared_quantity"
                  rules="required|min:1"
                  v-slot="{ errors }"
                >
                  <span class="text-muted mt-5 mb-2 d-block">{{
                    $t("mealsystem.recipes.label.prepared_quantity")
                  }}</span>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="
                      $t('mealsystem.recipes.placeholder.prepared_quantity')
                    "
                    v-model="selectedRecipe.prepared_quantity"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  name="prepared_quantity"
                  rules="required|min:1"
                  v-slot="{ errors }"
                >
                  <span class="text-muted mt-5 mb-2 d-block">{{
                    $t("mealsystem.recipes.label.meal_count")
                  }}</span>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="
                      $t('mealsystem.recipes.placeholder.meal_count')
                    "
                    v-model="selectedRecipe.meal_count"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </b-tab>
          <b-tab
            :title="$t('mealsystem.recipes.title.tab_assigned_raw_materials')"
            v-if="selectedRecipe.recipe_id"
          >
            <div class="row">
              <div class="col-3 form-group">
                <label class="col-form-label text-success font-weight-bolder">{{
                  $t("mealsystem.recipes.title.add_raw_material")
                }}</label>
              </div>
              <div class="col-6">
                <vs-select
                  v-if="raw_materials"
                  :options="raw_materials"
                  :placeholder="
                    $t('mealsystem.placeholder.search_raw_material')
                  "
                  label="name"
                  :reduce="(raw_materials) => raw_materials.item_id"
                  v-model="newRawMaterialSelect"
                />
              </div>
              <div class="col-3">
                <b-button variant="success" @click="addNewRawMaterial()">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                  </span>
                  {{ $t("mealsystem.recipes.button.create_raw_material") }}
                </b-button>
              </div>
            </div>

            <table
              class="table"
              v-if="
                selectedRecipe.raw_materials &&
                selectedRecipe.raw_materials.length > 0
              "
            >
              <thead>
                <tr>
                  <th>
                    {{ $t("mealsystem.recipes.label.raw_material") }}
                  </th>
                  <th width="250">
                    {{ $t("mealsystem.recipes.label.amount") }}
                  </th>
                  <th width="90"></th>
                </tr>
              </thead>
              <tr
                v-for="(raw_material, index) in selectedRecipe.raw_materials"
                :key="index"
              >
                <td class="form-group">
                  <label class="col-form-label">{{ raw_material.name }}</label>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="raw_material.pivot.amount"
                    class="form-control form-control-solid"
                    :placeholder="$t('mealsystem.recipes.placeholder.amount')"
                  />
                </td>
                <td>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-danger
                      btn-sm
                      btn-icon
                    "
                    @click="deleteRawMaterial(raw_material)"
                    ><i class="ki ki-bold-close icon-sm text-danger"></i
                  ></a>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-success
                      btn-sm
                      btn-icon
                    "
                    @click="updateRawMaterial(raw_material)"
                    ><i class="far fa-save text-success"></i
                  ></a>
                </td>
              </tr>
            </table>

            <div class="py-20 text-muted text-center" v-else>
              {{ $t("mealsystem.recipes.title.no_raw_material_in_recipe") }}
            </div>
          </b-tab>
        </b-tabs>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="success" @click="updateRecipe(selectedRecipe)">
          {{ $t("mealsystem.recipes.button.save_recipe") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Pagination from "@/view/content/Pagination";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      recipes: null,
      groups: null,
      searchGroup: null,
      loading: false,
      page: 1,
      search: "",
      selectedRecipe: {},
      heat_treatment_types: ["BAKE", "COLD"],
      raw_materials: null,
      newRawMaterialSelect: null,
    };
  },
  metaInfo() {
    return { title: this.$t("mealsystem.meta.recipes_list") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("mealsystem.meta.recipes"),
        subtitle: this.$t("mealsystem.meta.recipes_list"),
      },
    ]);
  },
  components: {
    Pagination,
  },
  beforeMount() {
    this.getRecipes();
    this.getGroups();
    this.getRawMaterials();
  },
  methods: {
    getRecipes() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/recipes?page=" +
          this.page +
          "&perpage=" +
          this.perpage +
          "&search=" +
          this.search +
          "&groups=" +
          this.searchGroup
      ).then((response) => {
        this.recipes = response.data;
        this.loading = false;
      });
    },
    getGroups() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/recipe-groups").then((response) => {
        this.groups = response.data.data;
        this.loading = false;
      });
    },
    getRawMaterials() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/warehouse").then((response) => {
        this.raw_materials = response.data.data;
        this.loading = false;
      });
    },
    setNewRecipe() {
      this.selectedRecipe = {
        meal_count: 10
      };
    },
    setRecipe(recipe) {
      this.selectedRecipe = recipe;
    },
    getRecipeDetail(recipe) {
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/recipes/" + recipe.recipe_id).then(
        (response) => {
          this.selectedRecipe = response.data.data;
        }
      );
    },
    deleteRecipe(recipe) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost("/meal-system/recipes/" + recipe.recipe_id + "/delete")
        .then(() => {
          this.loading = false;
          this.$bvModal.hide("modal-recipe-delete");
          this.getRecipes();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    addNewRawMaterial() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/recipes/" +
          this.selectedRecipe.recipe_id +
          "/raw-materials",
        {
          item_id: this.newRawMaterialSelect,
          amount: 1,
        }
      )
        .then(() => {
          this.loading = false;
          ApiService.setHeader();
          ApiService.apiGet(
            "/meal-system/recipes/" + this.selectedRecipe.recipe_id
          ).then((response) => {
            this.selectedRecipe = response.data.data;
            this.newRawMaterialSelect = null;
          });
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    deleteRawMaterial(raw_material) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/recipes/raw-materials/" +
          raw_material.pivot.recipe_raw_material_id +
          "/delete"
      )
        .then(() => {
          this.loading = false;
          ApiService.setHeader();
          ApiService.apiGet(
            "/meal-system/recipes/" + raw_material.pivot.recipe_id
          ).then((response) => {
            this.selectedRecipe = response.data.data;
          });
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    updateRawMaterial(raw_material) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/recipes/raw-materials/" +
          raw_material.pivot.recipe_raw_material_id +
          "/update",
        {
          item_id: raw_material.item_id,
          amount: raw_material.pivot.amount,
        }
      )
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    removeFilters() {
      this.page = 1;
      this.search = "";
      this.searchGroup = null;
      this.getRecipes();
    },
    searchRecipes() {
      this.page = 1;
      this.getRecipes();
      this.recipeSearch = {
        search: this.search,
      };
    },
    updateRecipe(recipe) {
      this.loading = true;
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        }

        if (recipe.recipe_id) {
          this.endpoint =
            "/meal-system/recipes/" + recipe.recipe_id + "/update";
        } else {
          this.endpoint = "/meal-system/recipes";
        }

        ApiService.setHeader();
        ApiService.apiPost(this.endpoint, {
          name: recipe.name,
          recipe_number: recipe.recipe_number,
          heat_treatment: recipe.heat_treatment,
          prepared_quantity: recipe.prepared_quantity,
          meal_count: recipe.meal_count,
          nutritional_values: recipe.nutritional_values,
          recipe_group_id: recipe.group,
        })
          .then(() => {
            this.loading = false;
            this.$bvModal.hide("modal-recipe-edit");
            this.getRecipes();
          })
          .catch((error) => {
            checkErrors(error);
          });
      });
    },
  },
};
</script>